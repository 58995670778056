/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Markdown from 'markdown-to-jsx';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Image from 'gatsby-image';
import Lead from '../components/Common/Lead';

const Article = styled.article`
  margin: 24px 0 0;

  h1 {
    margin: .5rem 0 2rem;
  }

  figure {
    background: #765873;
    height: 500px;
    width: 100%;
  }

  .gatsby-image-wrapper {
    @media only screen and (max-width: 960px) {
      height: 300px!important;
    }
    width: 100%!important;
  }

  .article {
    &__date {
      font-weight: 700;
      color: #c8608b;
      font-size: 1.6rem;
      margin: 0;
    }
    &__author {
      margin: 50px 0 0;
      padding: 20px 0 0;
      position: relative;

      &::before {
        content: '';
        border-top: 1px solid rgba(199,87,132,.25);
        left: 0;
        top: 0;
        position: absolute;
        width: 100px;
      }
    }
    &__content {
      max-width: 900px;
    }
  }

  .gatsby-image-wrapper {
    margin: 0 0 50px;

    img {
      max-width: 100%;
    }
  }

  ul,ol {
    margin: 0 0 3rem;

    li {
      & + li {
        margin-top: 5px;
      }
    }
  }

  cite {
    line-height: 150%;
    max-width: 755px;
  }

  table {
    border: 1px solid rgba(199, 87, 132, 0.25);
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 1.6rem;
    margin: 0 0 50px;
    width: 100%;

    &.text-left td {
      text-align: left;
    }

    .tab-best-sell {
      tbody {
        td {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            text-align: right;
          }
          &:nth-child(5),
          &:nth-child(8) {
            text-align: right;
          }
        }
      }
    }
  }

  table.tab-best-sell tbody {
    tr {
      &:nth-child(1) {
        td:nth-child(1) {
          background: gold;
          color: #000;
        }
      }

      &:nth-child(2) {
        td:nth-child(1) {
          background: #919191;
        }
      }

      &:nth-child(3) {
        td:nth-child(1) {
          background: #763a13;
        }
      }
    }
  }

  table.tab-general thead tr:nth-child(2) th:nth-child(9),
  table.tab-general thead tr:nth-child(3) th:nth-child(13),
  table.tab-general tbody tr td:nth-child(13) {
    border-left: 5px solid rgba(199, 87, 132, 0.25);
  }

  tfoot {
    td {
      font-size: 14px;
      padding: 10px 15px;
      text-align: left;

      p {
        margin: 0 0 5px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  th {
    background: #340f2f;
    padding: 2px 3px;

    em {
      font-size: 14px;
      font-style: normal;
    }
  }

  td,th {
    border: 1px solid rgba(199, 87, 132, 0.25);
    text-align: center;
  }

  tr:nth-child(even) td {
    background: #331b2f;
  }

  tbody tr:hover td {
    background: #45153f;
  }

  td {
    transition: background 100ms ease-in;
    height: 45px;
    padding: 0 5px;
  }

  a:hover,
  a:focus {
    text-decoration: none;
  }
`;

export const query = graphql`
  query querySingleArticle($slug: String!) {
    mdx(frontmatter: {slug: {eq: $slug}}) {
      frontmatter {
        title
        slug
        date
        lead
        author
        featuredImage {
          childImageSharp {
            fixed(width: 1000) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
      body
    }
  }
`;

const PostLayout = ({ data }) => (
  <Article>
    <p className="article__date">{data.mdx.frontmatter.date}</p>
    <h1 dangerouslySetInnerHTML={{ __html: data.mdx.frontmatter.title }}></h1>
    <Lead
      maxWidth="900px"
      desc={data.mdx.frontmatter.lead}
    />
    <Image fixed={data.mdx.frontmatter.featuredImage.childImageSharp.fixed} />
    <div className="article__content">
      <Markdown>{data.mdx.body}</Markdown>
    </div>

    {/* data.mdx.frontmatter.author && (
      <div className="article__author">
        <p>
          Autor: <em>{data.mdx.frontmatter.author}</em>
        </p>
      </div>
    ) */}
  </Article>
);

export default PostLayout;
